import React from 'react';

import DefaultTable from '../components/Table';

const Dashboard = (props) => (
  <div>
    <DefaultTable isAuthenticated={props.isAuthenticated} clientReportData={props.clientReportData} monthlyReportData={props.monthlyReportData} />
  </div>
);

export default Dashboard;
