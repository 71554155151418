import React, { Component } from 'react';

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="my-5 text-center">Welcome to your Veta Virtual dashboard</h2>
        <p>Welcome to Veta Virtual!  We are very excited that you decided to partner with us!  This document outlines our procedures for how we deliver our services and other key information regarding your account.</p>

        <strong>Dashboard Info:</strong>
        <ul>
          <li>Please authenticate with your given credentials. Once you are logged in, you can update your password.</li>
          <li>Usage Time: Your usage time is calculated by adding the Call Time and After-Call Work Time (where we type the call notes and send them to you) which equals the Total Handle Time.</li>
          <li>Usage totals are approximate and will not be used for billing purposes.</li>
        </ul>

        <strong>Email Addresses</strong>
        <ol>
          <li><a href="mailto:support@vetavirtual.com">support@vetavirtual.com</a> - Any time you have a general question, inquiry or request, please email this address. This inbox is monitored by multiple account managers who can respond quickly to any request.</li>
          <li><a href="mailto:callnotes@vetavirtual.com">callnotes@vetavirtual.com</a> - Our receptionists will send you Call Notes after each call is completed from this email address. You can reply to these emails if you have any concerns or requests about that specific call.</li>
          <li><a href="mailto:outbound@vetavirtual.com">outbound@vetavirtual.com</a> - Email any Outbound Call Requests to this email address. Once a Receptionist completes your Outbound Call Request, they will reply to it from the same email address to let you know that the request has been completed, along with any notes from the call.</li>
          <li><a href="mailto:billing@vetavirtual.com">billing@vetavirtual.com</a> - Please use this email for any billing-related requests or inquiries.</li>
        </ol>
        <p><u>Please add these 4 email addresses, as well as anything ending with <strong>@five9.com</strong> as contacts in your inbox to prevent any emails from going into your Spam folder.</u></p>

        <strong>Main Phone Numbers:</strong>
        <p>CA: 647-250-0403
          <br></br>
          US: 929-226-0679
        </p>

        <strong>Main Hours of Operation:</strong>
        <p>Monday- Friday 9:00AM-9:00PM EST</p>

        <p><strong><u>Billing Schedule</u></strong></p>
        <p><i>Each billing cycle is one calendar month, and billing is processed at the beginning of each month.  A prepaid plan billing cycle will include the future month (prepaid portion), and will reconcile any additional minutes from the previous month (postpaid portion).  For example:  Prepayment is made for the first service month.  Service begins on January 1st and the first period ends on January 31st.  The next invoice is issued on February 3rd, which includes prepayment for February and any additional services used during the previous month of January.</i></p>

        <p><strong><u>Holiday Schedule</u></strong></p>
        <p><i>Please note:  We are closed for all federal and national holidays in the USA and Canada.  We return any missed phone calls or voicemail messages during the next business day.  We publish the exact dates we are closed every year on our website.</i></p>

        <strong>Canada</strong>
        <ul>
          <li>New Year’s Day</li>
          <li>Family Day</li>
          <li>Good Friday</li>
          <li>Victoria Day</li>
          <li>Canada Day</li>
          <li>Civic Holiday</li>
          <li>Labor Day</li>
          <li>Thanksgiving</li>
          <li>Christmas Day</li>
          <li>Boxing Day</li>
        </ul>

        <strong>USA</strong>
        <ul>
          <li>New Year’s Day</li>
          <li>Martin Luther King Jr. Day</li>
          <li>Presidents’ Day</li>
          <li>Memorial Day</li>
          <li>Independence Day</li>
          <li>Labor Day</li>
          <li>Columbus Day</li>
          <li>Thanksgiving</li>
          <li>Christmas Day</li>
        </ul>

        <p><strong><u>Cancellation Policy Summary</u></strong></p>
        <ul>
          <li>Our month-to-month plans generally require one billing cycle advance notice for cancellation.</li>
          <li>Our fixed term commitments generally require one billing cycle advance notice for cancellation and may have other requirements.</li>
          <li>For further information, please refer to the <a href="https://www.vetavirtual.com/terms-of-use/">General Terms and Conditions</a>, or the specific agreement, if applicable.</li>
        </ul>

        <p>
          VVRSP.2023.1.4 - 19 Sep 2023
        </p>
      </div>
    );
  }
}

export default Content;
