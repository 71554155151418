import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./views/Home";
import DashboardView from "./components/DashboardView";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const {user, isAuthenticated, isLoading, error } = useAuth0();

  function convertTZ(date, tzString) {
    var newDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
    return newDate;   
  }

  const now = convertTZ(new Date(), "America/Toronto")

  if (error) {return <div>Oops... {error.message}</div>;}

  if (isLoading) {
    return <Loading />;
  }
  
  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home}>
              {isAuthenticated?<Route
              render={(props) => (
                <DashboardView {...props} isAuthenticated={isAuthenticated} 
               company={user["https://dashboard.vetavirtual.com/user_authorization"].user_metadata.Organization} 
              //  company={"Veta Virtual Mainline CA"}
                startDate={convertTZ(new Date(now.getFullYear(), now.getMonth(), 1), "America/Toronto")}
                endDate={now}/>
              )} />:Home}
            </Route>
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
