import React from "react";
import logo from "../assets/logo.svg";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <img alt={"logo"} src={logo}/>
    <p>
      Copyright Veta Virtual {new Date().getFullYear()}
    </p>
  </footer>
);

export default Footer;
