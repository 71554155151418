import 'react-datepicker/dist/react-datepicker.css';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import Dashboard from '../views/Dashboard';

const requestURL = "https://api.five9.com/wsadmin/4/AdminWebService";

require("dotenv").config();
class DashboardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      tableData: "",
      clientReportData: "",
      monthlyReportData: "",
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      company: this.props.company,
    };
  }

  componentDidMount() {
    this.setState({ isNew: true });
  }

  /**
   * Minutes in currently viewed billing period:
   * 
   */
  async componentDidUpdate(prevProps, prevState) {
    if (new Date(this.state.startDate) > new Date(this.state.endDate)) {
      return;
    } 

    if (this.state.endDate !== prevState.endDate || this.state.startDate !== prevState.startDate || this.state.isNew !== prevState.isNew) {
      var parseString = require('xml2js').parseString;

      async function isReportRunning(reportDataFieldName, response, self) {
        const delay = ms => new Promise(res => setTimeout(res, ms));
        try {
          var reportID;

          parseString(response, function (err, result) {
            reportID = result["env:Envelope"]["env:Body"][0]["ns2:runReportResponse"][0]["return"][0];
          });

          const isReportRunningRequest = '<env:Envelope xmlns:xsd="http://www.w3.org/2001/XMLSchema"\
                          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\
                          xmlns:tns="http://service.admin.ws.five9.com/"\
                          xmlns:env="http://schemas.xmlsoap.org/soap/envelope/"\
                          xmlns:ins0="http://jaxb.dev.java.net/array">\
                          <env:Body>\
                          <tns:isReportRunning>\
                          <identifier>'+ reportID + '</identifier>\
                          <timeout>'+ 0 + '</timeout>\
                          </tns:isReportRunning>\
                          </env:Body>\
                      </env:Envelope>';

          const isReportRunningRequestHeaders = {
            method: 'POST',
            headers: {
              Authorization: 'Basic ' + process.env.REACT_APP_AUTH_TOKEN,
              'Content-Type': 'text/xml',
              'Accept-Encoding': 'gzip,deflate'
            },
            body: isReportRunningRequest,
          };
          const metadata = await fetch(requestURL, isReportRunningRequestHeaders);
          let metadataString = await metadata.text();
          var running = false;
          const parseStringResolver = (resolve) => {
            parseString(metadataString, function (err, result) {
              running = result["env:Envelope"]["env:Body"][0]["ns2:isReportRunningResponse"][0]["return"][0] === 'true';
              if (running) {
                delay(5000)
                  .then(() => {
                    return fetch(requestURL, isReportRunningRequestHeaders)
                  })
                  .then((metaData) => {
                    return metaData.text();
                  })
                  .then((metaDataString) => {
                    metadataString = metaDataString;
                    parseStringResolver(resolve);
                  });
              } else {
                resolve();
              }
            });
          };
          const parseStringPromise = (running) => new Promise(resolve => {
            parseStringResolver(resolve);
          });
          await parseStringPromise(running);
          const reportResultRequest = '<env:Envelope xmlns:xsd="http://www.w3.org/2001/XMLSchema"\
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\
          xmlns:tns="http://service.admin.ws.five9.com/"\
          xmlns:env="http://schemas.xmlsoap.org/soap/envelope/"\
          xmlns:ins0="http://jaxb.dev.java.net/array">\
          <env:Body>\
          <tns:getReportResult>\
          <identifier>'+ reportID + '</identifier>\
          </tns:getReportResult>\
          </env:Body>\
          </env:Envelope>';

          const reportResultRequestHeaders = {
            method: 'POST',
            headers: {
              Authorization: 'Basic ' + process.env.REACT_APP_AUTH_TOKEN,
              'Content-Type': 'text/xml',
              'Accept-Encoding': 'gzip,deflate'
            },
            body: reportResultRequest,
          };
          const reportResults = await fetch(requestURL, reportResultRequestHeaders);
          const reportResultsString = await reportResults.text();
          self.setState({ [reportDataFieldName]: reportResultsString });
        } catch (error) {
          console.error(error);
        }
      }

      var companies = this.state.company.split(', ');

      var companyFilter = "";

      for (var i = 0; i < companies.length; i++) {
        companyFilter = companyFilter + '<objectNames>' + companies[i] + '</objectNames>'
      }

      let self = this;
      const monthlyReportName = 'Monthly Usage Report (Dashboard) - DO NOT CHANGE';
      const clientUsageReportName = 'Client Usage Report - Do Not Change';
      async function getSharedReports(companyFilter, reportName, self) {
        var data = '<env:Envelope xmlns:xsd="http://www.w3.org/2001/XMLSchema"\
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\
        xmlns:tns="http://service.admin.ws.five9.com/"\
        xmlns:env="http://schemas.xmlsoap.org/soap/envelope/"\
        xmlns:ins0="http://jaxb.dev.java.net/array">\
        <env:Body>\
        <tns:runReport>\
        <folderName>Shared Reports</folderName>\
        <reportName>' + reportName + '</reportName>\
        <criteria>\
        <time>\
        <start>'+ self.state.startDate.toISOString() + '</start>\
        <end>'+ self.state.endDate.toISOString() + '</end>\
        </time>\
        <reportObjects>\
        <objectType>Campaign</objectType>\
            '+ companyFilter + '\
        </reportObjects>\
        </criteria>\
            </tns:runReport>\
            </env:Body>\
        </env:Envelope>'

        const requestMetadata = {
          method: 'POST',
          headers: {
            Authorization: 'Basic ' + process.env.REACT_APP_AUTH_TOKEN,
            'Content-Type': 'text/xml',
            'Accept-Encoding': 'gzip,deflate'
          },
          body: data,
        };

        try {
          const response = await fetch(requestURL, requestMetadata);
          const responseBody = await response.text();
          return responseBody;
        } catch (error) {
          console.error(error);
        }
      }



      const clientUsageReportData = await getSharedReports(companyFilter, clientUsageReportName, self);
      await isReportRunning('clientReportData', clientUsageReportData, self);
      const monthlyReportData = await getSharedReports(companyFilter, monthlyReportName, self);
      await isReportRunning('monthlyReportData', monthlyReportData, self);
    }
  }

  render() {
    function convertTZ(date, tzString) {
      var newDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
      return newDate;
    }
    return (
      <div>
        <div>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            The usage minutes shown in dashboard is only for reference purposes, final usage will be adjusted at the end of billing cycle.
          </Alert>
        </div>
        <br />
        <div>
          <h2>Date Range (EST):</h2>
          <DatePicker
            selected={this.state.startDate}
            onChange={date => this.setState({ startDate: convertTZ(date, "America/Toronto") })}
            selectsStart
            endDate={this.state.endDate}
          />
          <br />
          <DatePicker
            selected={this.state.endDate}
            onChange={date => this.setState({ endDate: convertTZ(date, "America/Toronto") })}
            selectsEnd
            startDate={this.state.startDate}
            minDate={this.state.startDate}
          />
          <br />
          <br />
        </div>
        <div>
          <Dashboard isAuthenticated={this.props.isAuthenticated} clientReportData={this.state.clientReportData} monthlyReportData={this.state.monthlyReportData} />
        </div>
      </div>
    );
  }
}

export default DashboardView;
