import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment-timezone';
import React from 'react';
import ReactTextCollapse from 'react-text-collapse';
import { Container } from 'reactstrap';

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },

});

let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
};

let convertTZ = (date, tzString) => {
  var newDate = moment.tz(date, "ddd, D MMM YYYY HH:mm:ss", "America/Los_Angeles").tz(tzString).format('MM/DD/YYYY, h:mm:ss A');
  return newDate + " EST";
}


export default function DefaultTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  if (!props.isAuthenticated) {
    return null;
  }

  if (props.clientReportData === "" || props.monthlyReportData === "") {
    return "";
  }


  var parseString = require('xml2js').parseString;
  var relevantInfo;
  var monthlyReportData;

  parseString(props.clientReportData, function (err, result) {
    relevantInfo = result["env:Envelope"]?.["env:Body"]?.[0]?.["ns2:getReportResultResponse"]?.[0]?.["return"]?.[0];
  });

  parseString(props.monthlyReportData, function (err, result) {
    monthlyReportData = result["env:Envelope"]?.["env:Body"]?.[0]?.["ns2:getReportResultResponse"]?.[0]?.["return"]?.[0];
  });

  var columnNames = relevantInfo.header[0].values[0].data
  var rows = relevantInfo.records;

  var columnNamesList = [];
  Object.keys(columnNames).forEach(function (key) {
    columnNamesList.push(columnNames[key]);
  })

  var rowsList = [];
  var dispositionMap = {};
  var totalSeconds = 0;
  if (rows !== undefined && rows != null) {

    rows.sort(function (a, b) {
      return new Date(b.values[0].data[0].replace(',', '')) - new Date(a.values[0].data[0].replace(',', ''));
    });

    Object.keys(rows).forEach(function (key) {
      var row = rows[key].values[0].data;
      if (row[13].toString() !== "Internal" &&
        row[4].toString() !== "3rd party transfer" && row[13].toString() !== "Abandon" && row[13].toString() !== "Sent To Voicemail") {

        if (row[13].toString() !== "Dummy") {
          // adds info to the table
          rowsList.push(row);
        }

        // aggregate the timestamp
        if (row[13].toString() !== "Internal") {
          var seconds = row[9].toString().split(":");
          var secondsSum = (+seconds[0]) * 60 * 60 + (+seconds[1]) * 60 + (+seconds[2]);

          if (secondsSum > 30) {
            totalSeconds = totalSeconds + secondsSum;
          }
        }

        // gets the disposition info
        var disposition = row[13].toString();
        if (disposition === "Dummy") {
          disposition = "Spam/Other"
        }
        if (disposition in dispositionMap) {
          var sum = dispositionMap[disposition] + 1
          dispositionMap[disposition] = sum
        } else {
          dispositionMap[disposition] = 1
        }
      }
    })
  }

  var sorted = Object.keys(dispositionMap);
  sorted.sort();
  const timeSplit = monthlyReportData && monthlyReportData.records && monthlyReportData.records.length > 0 && monthlyReportData.records[0].values[0].data[3].split(':');
  if (timeSplit) {
    const [hours, minutes, seconds] = timeSplit;
    const actualMinutes = (parseInt(hours) * 60) + parseInt(minutes) + (parseInt(seconds) > 30 ? 1 : 0);
    var timeString = actualMinutes;
  }


  return (
    <Container style={{ padding: '0' }}>
      <h3 style={{ textAlign: 'left' }}>{"Minutes in currently viewed billing period: " + (timeString || 0)}</h3>
      {
        <div className="dashboard-info" style={{ textAlign: 'left' }}>
          {sorted.map((val, k) => {
            return (<p key={val} style={{ lineHeight: "50%" }}>{val + " : " + dispositionMap[val]}</p>)
          })
          }
          <br />
        </div>
      }
      <h1>{"Call log"}</h1>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="call info table" >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} variant="head">{"Timestamp"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"Call Type"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"From"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"To"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"Customer Name"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"Customer Company"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"Agent Name"}</TableCell>
              <TableCell className={classes.tableCell} variant="head">{"Disposition"}</TableCell>
              <Tooltip title="Handle time equals to talk time plus after call work time" placement="top"><TableCell className={classes.tableCell} variant="head">{"Handle Time "}<FontAwesomeIcon icon={faQuestionCircle} /></TableCell></Tooltip>
              <TableCell className={classes.tableCell} variant="head">{"Call Note"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row[0] + 'row'}>
                <TableCell align="left" key={row[0] + 'timestamp'}>{convertTZ(row[0].toString(), "America/Toronto")}</TableCell>
                <TableCell className={classes.tableCell} align="left" key={row[0] + 'calltype'}>{row[4].toString() === "Manual" ? "Outbound" : row[4].toString()}</TableCell>
                <TableCell className={classes.tableCell} align="left" key={row[0] + 'from'}>{row[4].toString() === "Manual" ? row[5].toString() : formatPhoneNumber(row[1].toString())}</TableCell>
                <TableCell className={classes.tableCell} align="left" key={row[0] + 'to'}>{row[4].toString() === "Inbound" ? row[5].toString() : formatPhoneNumber(row[1].toString())}</TableCell>
                <TableCell align="left" key={row[0] + 'custname'}>{row[17].toString() === "[object Object]" ? "" : row[17].toString()}</TableCell>
                <TableCell align="left" key={row[0] + 'company'}>{row[18].toString() === "[object Object]" ? "" : row[18].toString()}</TableCell>
                <TableCell align="left" key={row[0] + 'agentname'}>{row[6].toString()}</TableCell>
                <TableCell align="left" key={row[0] + 'disposition'}>{row[13].toString()}</TableCell>
                <TableCell align="left" key={row[0] + 'handletime'}>{row[9].toString()}</TableCell>
                <TableCell align="left" key={row[0] + 'notes'}>{row[14].toString() === "[object Object]" ? "" : <ReactTextCollapse options={{
                  collapse: true,
                  collapseText: '... show more',
                  expandText: 'show less',
                  minHeight: 100,
                  maxHeight: 300,
                  textStyle: {
                    color: "blue"
                  }
                }}>{row[14].toString()}</ReactTextCollapse>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rowsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <br /><br />
    </Container>
  );
}
